import EventcodeFunctions from "./EventcodeFunctions";
const moment = require('moment-timezone');

function clearGlobalVariables() {

    /*
        Suresh: do we to add this?

    global.$regitems            = null;
    global.$regsubitems         = null;
    global.$regdropdownoptions  = null;
    */

    global.$selectedregoptions = null;
    global.$registereditems = null;
    global.$totalregitems = null;
    global.$attendeeregitems = null;
    global.$guestregitems = null;
    global.$regItemsForSignupCount = null;

    global.$deferredpromo = null;
    global.$deferredpromoamount = null;
    global.$regamount = null;
    global.$finalamount = null;

    global.$hideregisterbutton = null;
    global.$selectedPaymentOption = null;
    global.$regdiscountinfo = null;
    global.$regattendeestatus = null;
    global.$reggueststatus = null;
    global.$regemail = null;
    global.$reguestemail = null;
    global.$regattendeeinfo = null;
    global.$regguestinfo = null;
    global.$loggedinguest = null;
    global.$guestexist = null;
    global.$terms1status = null;
    global.$terms2status = null;
    global.$terms3status = null;
    global.$paymentid = null;
    global.$upgradepricediff = null;
    // global.$loggedinuserregitems = null;
    global.$regitemtoreducecount = null;
    global.$isattendeeregistered = null;
    global.$isguestregistered = null;
    global.$transactionfee = null;
    global.$openpaymentpopup = false; // this will cntrols payment popup
}

function printGlobalRegItems()
{
    console.log('------- Reg Items ----------')
    
    //console.log(`regitems:`)
    //console.log(global.$regitems)
    //console.log(`regsubitems:`)
    //console.log(`regsubitems)
    //console.log(`regdropdownoptions = ${global.$regdropdownoptions}`)

    console.log(`global.$selectedregoptions:`)
    console.log(global.$selectedregoptions)

    console.log(`global.$registereditems:`)
    console.log(global.$registereditems)

    console.log(`regItemsForSignupCount`)
    console.log(getRegItemsForSignupCount(global.$selectedregoptions))

    console.log(`global.$attendeeregitems:`)
    console.log(global.$attendeeregitems)

    console.log(`global.$guestregitems`)
    console.log(global.$guestregitems)

    console.log(`global.$totalregitems`)
    console.log(global.$totalregitems)

    console.log('----------------------------')
}

function printGlobalAmounts()
{
    console.log('------- Amounts ----------')

    console.log(`regamount = ${global.$regamount}`)   
    console.log(`finalamount = ${global.$finalamount}`)   

    //console.log(`regamount = ${global.$regamount}`)   
    //console.log(`regamount = ${global.$regamount}`)   
    console.log('---------------------------')
}

function isUserLoggedin() {
    var result = false;
    if (global.$loginstatus.includes("true")) {
        result = true;
    }
    return result;
}
function loginUserType(usertype) {
    var result = false;
    
    if (global.$loginstatus == "true") {
        if (global.$userstype.includes(usertype) ) {
            result = true
        }
        else {
            result = false
        }
    }
    else {
        result = true
    }
    return result
}

function enableRegComponents(tab) {
    let result = true;
    if (global.$registrationform.setup[tab] && global.$registrationform.setup[tab].enablenewregistration != null) {
        result = global.$registrationform.setup[tab].enablenewregistration
    }
    return result
}
function enableUpdateRegComponents(tab) {
    let result = true;
    if (global.$registrationform.setup[tab] && global.$registrationform.setup[tab].enableupdateregistration != null) {
        result = global.$registrationform.setup[tab].enableupdateregistration
    }
    return result
}

function convertGroupname(groupnames){
    var groupnamestring = groupnames.replace('names#:','')
    var groupnameArr = groupnamestring.split(';');
    var groupArray = [];
    var groupnameArray = groupnameArr.map((group) => {
        var splitname = group.split(':');
        groupArray.push(splitname[1])
    })
    return groupnameArray;
}

function isAttendeeTabEnabled() {
    var result = false;
    if (global.$registrationform.setup != null && global.$registrationform.setup.attendee.enable == true) {
        result = true;
        
    }
    return result;
}
function isExhibitorTabEnabled() {
    var result = false;
    if (global.$registrationform.setup.exhibitor != null && global.$registrationform.setup.exhibitor.enable == true) {
        result = true;
    }
    return result;
}

function isSponsorTabEnabled() {
    var result = false;
    if (global.$registrationform.setup.sponsor != null && global.$registrationform.setup.sponsor.enable == true) {
        result = true;
    }
    return result;
}
function isTab1Enabled() {
    var result = false;
    if (global.$registrationform.setup != null && global.$registrationform.setup.tab1 && global.$registrationform.setup.tab1.enable == true) {
        result = true;

    }
    return result;
}
function isTab2Enabled() {
    var result = false;
    if (global.$registrationform.setup != null && global.$registrationform.setup.tab2 && global.$registrationform.setup.tab2.enable == true) {
        result = true;
    }
    return result;
}
function isTab3Enabled() {
    var result = false;
    if (global.$registrationform.setup != null && global.$registrationform.setup.tab3 && global.$registrationform.setup.tab3.enable == true) {
        result = true;
    }
    return result;
}
function isTab4Enabled() {
    var result = false;
    if (global.$registrationform.setup != null && global.$registrationform.setup.tab4 && global.$registrationform.setup.tab4.enable == true) {
        result = true;
    }
    return result;
}
function isTab5Enabled() {
    var result = false;
    if (global.$registrationform.setup != null && global.$registrationform.setup.tab5 && global.$registrationform.setup.tab5.enable == true) {
        result = true;
    }
    return result;
}
function isUserType(type) {
    var result = false;
    
    if (global.$userstype.includes(type)) {
        result = true;
    }
    return result;
}
function isGuestAdded() {
    var result = false;
    if (global.$attendeeguests != null && global.$attendeeguests != "" && global.$attendeeguests != " ") {
        result = true;
    }
    return result;
}
function activeTabIs(type, tab) {
    var result = false;
    if (type == tab) {
        result = true;
    }
    return result;
}
function isAttendeeTab(type) {
    var result = false;
    if (type == "attendee") {
        result = true;
    }
    return result;
}
function isExhibitorTab(type) {
    var result = false;
    if (type == "exhibitor") {
        result = true;
    }
    return result;
}
function isSponsorTab(type) {
    var result = false;
    if (type == "sponsor") {
        result = true;
    }
    return result;
}

function getDefaultTab() {
    var defaulttab = "tab1";
    if (isUserLoggedin()) {
            
        if(isTab1Enabled() && isUserType(global.$registrationform.setup.tab1.updateregistration)) {
            defaulttab = "tab1";
        }
        else if(isTab2Enabled() && isUserType(global.$registrationform.setup.tab2.updateregistration)) {
            defaulttab = "tab2";
        }
        else if(isTab3Enabled() && isUserType(global.$registrationform.setup.tab3.updateregistration)) {
            defaulttab = "tab3";
        }
        else if( isTab4Enabled() && isUserType(global.$registrationform.setup.tab4.updateregistration)) {
            defaulttab = "tab4";
        }
        else if( isTab5Enabled() && isUserType(global.$registrationform.setup.tab5.updateregistration) ) {
            defaulttab = "tab5";
        }
        
        
        global.$activetab = defaulttab;
        console.log(defaulttab);
        return defaulttab;
    }
    else {
        
        if (isTab1Enabled()) {
            defaulttab = "tab1";
            return defaulttab;
        }
        if (isTab2Enabled()) {
            defaulttab = "tab2";
            return defaulttab;
        }
        if (isTab3Enabled()) {
            defaulttab = "tab3";
            return defaulttab;
        }
        if (isTab4Enabled()) {
            defaulttab = "tab4";
            return defaulttab;
        }
        if (isTab5Enabled()) {
            defaulttab = "tab5";
            return defaulttab;
        }
       
    
    }
}

function getValueFromMeCard(tag, name) {

    //console.log(`getValueFromTag ${tag}, ${name}`)

    var val = ''

    name = name + ':'

    var begin = tag.indexOf(name)
    if (begin != -1) {

        begin = tag.indexOf(':', begin)
        var end = tag.indexOf(';', begin)

        if (begin != -1 && end != -1) {
            val = tag.substr(begin + 1, end - begin - 1)
        }
    }

    return val
}

function isUserAlreadyRegisteredForThisItem(regitem) {
    var result = false;
    if (global.$loggedinuserregitems != null && global.$loggedinuserregitems.includes(regitem)) {
        var regitems = JSON.parse(global.$loggedinuserregitems).filter((regitem)=>regitem.status == 'active')
    
        regitems.map(item => {
            if (item.itemid == regitem) {
                result = true;
            }
        })
    }
    return result;
}

function covertGroupIDToSectionID(groupid) {
    if (groupid == 'one')
        return 'section1'
    else if (groupid == 'two')
        return 'section2'
    else if (groupid == 'three')
        return 'section3'
    else if (groupid == 'four')
        return 'section4'
    else if (groupid == 'five')
        return 'section5'
}

function getRegItemsForGroup(groupname) {
    var result = [];
    // loop through all the reg items and filter by group
    global.$regitems.map(regitem => {
        //console.log(regitem);
        if (regitem.groupname == groupname) {
            result.push(regitem);
        }
    })
    return result;
}

function getRegItemStyle() {
    var regitemstyle = "";
    if (global.$registrationform != null && global.$registrationform.styles.regoptions != null) {
        regitemstyle = global.$registrationform.styles.regoptions.regitemname
    }
    return regitemstyle;
}

function isRadioInput(regitem) {
    if (regitem.inputtype.includes("radio")) {
        return true;
    }
    else {
        return false;
    }
}
function isCheckboxInput(regitem) {
    if (regitem.inputtype.includes("checkbox")) {
        return true;
    }
    else {
        return false;
    }
}
function isNumberInput(regitem) {
    if (regitem.inputtype.includes("number")) {
        return true;
    }
    else {
        return false;
    }
}
function isDropdownInput(regitem) {
    if (regitem.inputtype.includes("dropdown")) {
        return true;
    }
    else {
        return false;
    }
}

function getLoggedInUserRegItems() {
    var result = [];
   
    if (isUserLoggedin() && global.$loggedinuser != null ) {
        result = eval(global.$loggedinuser.regitemsjson);
    }
    global.$registereditems = result;
    return result;
}

function getLoggedInUserPendingAmount() {
    var result = 0;
    if (isUserLoggedin() && global.$loggedinuser != null) {
        result = Number(global.$loggedinuser.pendingamount);
    }
    return result;
}

/*
    Returns regitems selected by user from global.$regitems. 
*/
function getUserSelectedRegItems() {
    var result = [];
    //console.log(global.$regitems)
    global.$regitems.map(regitem => {
        if (regitem.isChecked == true && regitem.dropdownvalue.toLowerCase() !== "select") {
            result.push(regitem);
        }
    })
    //console.log(result);
    global.$selectedregoptions = result;
    return result;
}

function isRegDiscountExist() {
    if (global.$regdiscountinfo != null && global.$regdiscountinfo != "") {
        return true;
    }
    else {
        return false;
    }
}

/*
    Returns values based on input type

    dropdown:      
        Subitem:ID:itemid;N:item.name~~
    
    number: quantity > 0
        Regquantity:Q:quantity;N;name~~

    number: quantity == 0
        item.name~~
        
*/

function getRegItemsForSignupCount(selectedregoptions) {
    // var selectedregitems = "";
    var selectedregitemsjson = [];
    selectedregoptions.map((item) => {
        
        var name = "";
        if (item.inputtype == "dropdown" && item.dropdownvalue.toLowerCase() !== "select" && item.data4 != "multiselect" && item.itemid != "") {
            // name = "Subitem:ID:" + item.itemid + ";N:" + item.dropdownvalue;
            item.selecteddropdown.map((regitem)=>{
                selectedregitemsjson.push({
                    name:regitem.name,
                    type:"Subitem",
                    itemid:regitem.itemid,
                    qty:'1'
                })
            })
        }
        else if (item.inputtype == "dropdown" && item.dropdownvalue.toLowerCase() !== "select" && item.data4 == "multiselect" && item.itemid != "") {
           
            item.selecteddropdown.map((regitem)=>{
                selectedregitemsjson.push({
                    name:regitem.name,
                    type:"Subitem",
                    itemid:regitem.itemid,
                    qty:'1'
                })
            })
        }
        else if (item.inputtype == "number" && item.quantity != "0") {
            // name = "Regquantity:Q:" + item.quantity + ";N:" + item.name;
            selectedregitemsjson.push({
                name:item.name,
                type:"Regquantity",
                itemid:item.data5,
                qty:item.quantity
            })
        }
        else {
           
            selectedregitemsjson.push({
                name:item.name,
                type:" ",
                itemid:item.data5,
                qty:"1"
            })
        }
        
    })
    return selectedregitemsjson;
}

function isGuestEnabledFromConfig() {
    if (global.$registrationform.guests != null && global.$registrationform.guests.required == true) {
        return true;
    }
    else {
        return false;
    }
}

/*
    NOTE: In TravCon attendee, AfterParty and Bruch Tickets are part of Guest section
*/
function isUserSelectedGuest(selectedregoptions) {
    var result = false;
    selectedregoptions.map(item => {
        //console.log(item)
        if (item.groupname.includes("guest") || item.groupname.includes("Guest")) {
            result = true;
        }
    })
    //console.log(result);
    return result;
}

// check guest details 
function isGuestUserDetails() {
    var result = false;
    if (global.$regattendeeinfo != null && global.$regattendeeinfo.guestname != null && global.$regattendeeinfo.guestname != "" && global.$regattendeeinfo.guestname != " ") {
        result = true;
    }
    return result;
}

// check guest email and attendee email
function isCommonEmail() {
    var results = false;
    var result = isGuestUserDetails();
    if (result === true) {
        if (global.$regattendeeinfo.email === global.$regattendeeinfo.guests) {
            results = true;
        }
    }
    return results;
}

// check guest registration items
function isGuestUserRegItem(selectedregitems) {
    var guestInfoEntered = false;
    guestInfoEntered = isUserSelectedGuest(selectedregitems);
    return guestInfoEntered;
}

// check guest registeration ( update registration )
function isGuestUpdate() {
    var result = false;
    let guestregitems = global.$regattendeeinfo.regtype.split(",")

    global.$regitems.map((item) => {
        if (item.groupname.includes("guest") || item.groupname.includes("Guest")) {
            guestregitems.map(reg => {
                if(reg.includes(item.name)){
                    result = true;
                }
            })
            
        }
    })
    return result;
    
}

function sortRegItems(data) {
    return data.sort(function (a, b) {  // sorting the regoptions based onn data1 field
        var nameA = a.data1, nameB = b.data1

        if (nameA < nameB) //sort string ascending
            return -1
        if (nameA > nameB)
            return 1

        return 0 //default return value (no sorting) 
    })
}

// ------------------------------------------ Guest registration (metro2023convention) -------------------------------------------
// metro2023 code checking
function validateMetro(){
    var result = false;
    if (global.$code === "metro2023convention" || global.$code === "metro2023meeting" || global.$code === "metro2022conference") {
        result = true;
    }
    return result;
}

// run the registration page check guest regitem selected or not (without login default guest false)
function requiredGuestSection() {
    var required = false;
    var symbol = "";

    if (validateMetro()) {
        if (isUserLoggedin() && global.$loggedinuser != null) {
            var regitems = global.$registereditems
            regitems.map((item) => {
                if (item.name.includes("Metro Spouse") || item.name.includes("Non-Metro Spouse") || item.name.includes("Spouse")) {
                    required = true;
                    symbol = "*";
                    guestCheck(required,symbol);
                }
                else{
                    guestCheck(required,symbol)
                }
            })
            
        }
        else{
            guestCheck(required,symbol)
        }
    }
    
}

// guest regitem check 
function checkGuestRegItem(selected,checked){
    var required = false;
    var symbol = "";
    if ((selected.name.includes("Metro Spouse") || selected.name.includes("Non-Metro Spouse") || selected.name.includes("Spouse")) && checked) {
        required = true;
        symbol = "*";
        guestCheck(required,symbol)
    }
    else{
        guestCheck(required,symbol)
    }
}

// add or remove required guest input field
function guestCheck(required,symbol){
    var result = validateMetro();
    if  (result){
        disableGuestRequiredFields(required,symbol)
    }
    
}

// disable required guest input fields
function disableGuestRequiredFields(required,symbol){
        var personal = global.$registrationform.guests.personal;
        var work = global.$registrationform.guests.work;
        var contact = global.$registrationform.guests.contact;
        var other = global.$registrationform.guests.other;

        var emails = personal.email.required = required
        var emailRequiredFields = personal.email.requiredsymbol = symbol

        var fname = personal.fname.required = required
        var fnameRequiredFields = personal.fname.requiredsymbol = symbol

        var lname = personal.lname.required = required
        var lnameRequiredFields = personal.lname.requiredsymbol = symbol

        var title = work.title.required = required
        var titleRequiredFields = work.title.requiredsymbol = symbol

        var street = contact.street.required = required;
        var streetRequiredFields = contact.street.requiredsymbol = symbol

        var city = contact.city.required = required;
        var cityRequiredFields = contact.city.requiredsymbol = symbol

        var state = contact.state.required = required;
        var stateRequiredFields = contact.state.requiredsymbol = symbol

        var zip = contact.zip.required = required;
        var zipRequiredFields = contact.zip.requiredsymbol = symbol

        var residentstate = other.data2.required = required;
        var residentstateRequiredFields = other.data2.requiredsymbol = symbol
}

function createdatetime(){
    var datatime = moment().tz(`${global.$settings.timezone}`).format('YYYY/MM/DD HH:mm:ss A');
    //var datatime = moment().tz(`Asia/Kolkata`).format('YYYY/MM/DD HH:mm:ss A');
    var timestamp = String(moment(datatime,'YYYY/MM/DD HH:mm:ss A').unix());
    
    return {datatime,timestamp};
}



function selectedRegOptions(regitem){
    let selectedItem = {
        id:createdatetime().timestamp,
        name:"",
        price:"",
        type:"",
        itemid:"",
        qty:"1",
        cdate:createdatetime().datatime,
        udate:"",
        status:"active",
        data1:"",
        data2:"",
        data3:"",
        data4:"",
        data5:""
    }
    if (regitem.inputtype == "radio") {
        selectedItem.name = regitem.name
        selectedItem.price = regitem.amount
        selectedItem.type = regitem.inputtype
        selectedItem.itemid = regitem.data5
    }
    else if (regitem.inputtype == "dropdown") {       
        selectedItem.name = regitem.name
        selectedItem.price = regitem.amount
        selectedItem.type = regitem.inputtype
        selectedItem.itemid = regitem.data5
        selectedItem.subitems = regitem.selecteddropdown
    }
    else if (regitem.inputtype == "number")  {       
        selectedItem.name = regitem.name
        selectedItem.price = regitem.amount
        selectedItem.type = regitem.inputtype
        selectedItem.itemid = regitem.data5
        selectedItem.qty = regitem.quantity
    }
    else if (regitem.inputtype == "checkbox") {     
        selectedItem.name = regitem.name
        selectedItem.price = regitem.amount
        selectedItem.type = regitem.inputtype
        selectedItem.itemid = regitem.data5
    }
    
    if (regitem.groupname.includes("guest") || regitem.groupname.includes("Guest")) {
        global.$guestRegitemsjson.push(selectedItem);
        const ids = global.$guestRegitemsjson.map(o => o.id)
        global.$guestRegitemsjson = global.$guestRegitemsjson.filter(({id}, index) => !ids.includes(id, index + 1))
    }
    
    return selectedItem;

}

function checkRegitemInclude(purchaseItems,updateRegitemsArray) {
    const selectedPurchaseItems = purchaseItems.filter(obj => (
        obj.subitems ? obj.subitems.some(subObj => updateRegitemsArray.includes(subObj.itemid)) : 
        updateRegitemsArray.includes(obj.itemid)
    ));
    
    const selectPurchase = selectedPurchaseItems.length > 0;
    
    return {
        selectPurchase,
        purchaseItems: selectPurchase ? selectedPurchaseItems : []
    };
}


function validateSelectedRegItemPrice(regSetup,selectedItem,purchaseItems) {
    console.log(selectedItem);
    if (regSetup?.registrationconditions?.boothupgraderegitems) {
        let updateRegitemsArray = regSetup.registrationconditions.boothupgraderegitems
        
        // CHECK PRUCHASE REGITEM INCLUDES IN THE updateRegitemsArray LIST
        const selectPurchase = checkRegitemInclude(purchaseItems,updateRegitemsArray)
        const validateSelectedRegitem = selectedItem.selecteddropdown ? selectedItem.selecteddropdown.some(subObj => updateRegitemsArray.includes(subObj.itemid)) : updateRegitemsArray.includes(selectedItem.itemid)
        if (selectPurchase.selectPurchase && validateSelectedRegitem) {
            
            const hasMatchingItem = selectPurchase.purchaseItems.some(purchaseitem => {
                if (purchaseitem.subitems) {
                    if (selectedItem.selecteddropdown && selectedItem.selecteddropdown.length > 0) {
                        return Number(selectedItem.selecteddropdown[0].price) < Number(purchaseitem.subitems[0].price) ;
                    }
                } else {
                    return Number(selectedItem.price) < Number(purchaseitem.price);
                }
                return false;
            });

            return hasMatchingItem
        }
        return false;
        
    }
    
}

function getBoothUpgradeRegitemPrice(registeredItems,selectedItem,totalamount,tabId) {
    
    // TAKEN WEBCONFIG VALUE OTHERWISE FLASE
    let boothupgrade = global.$registrationform?.boothupgrade || false; 
    let boothupgradeRegitems;
    const regSetup = global.$registrationform.setup[tabId];

    // GETTING THE BOOTHUPGRADE REGITEMS ARRAY IN THE WEBCONFIG
    if (regSetup?.registrationconditions?.boothupgraderegitems) {
        boothupgradeRegitems = regSetup.registrationconditions.boothupgraderegitems
    }
    
    // CHECK IF BOOTH UPGRADE IS APPLICABLE AND USER IS LOGGED IN
    if (boothupgrade && isUserLoggedin()) {
        // FIND A MATCHING REGISTERED ITEM FOR BOOTH UPGRADE
        let matchingRegisteredItem = registeredItems.find(item =>
        // CHECK IF ANY SUBITEM OR THE ITEM ITSELF MATCHES A BOOTH UPGRADE ITEM ID
            (item.subitems && item.subitems.some(subitem => boothupgradeRegitems.includes(subitem.itemid))) ||
            boothupgradeRegitems.includes(item.itemid)
        );

        // GETTING THE PURCHASE SUBITEM PRICE ITEM 
        if (matchingRegisteredItem) {
            const matchingSubitem = matchingRegisteredItem.subitems && matchingRegisteredItem.subitems.find(subitem =>
                boothupgradeRegitems.includes(subitem.itemid)
            );
            if (matchingSubitem) {
                matchingRegisteredItem.price = matchingSubitem.price;
            }
        }

        // GETTING THE SELECTED REGITEM PRICE
        let selecteditemprice = 0
        global.$regitems.map((selecteditem) => {
            if (selecteditem.isChecked === true && selecteditem.dropdownvalue.toLowerCase() !== "select") {
                if (boothupgradeRegitems.includes(selecteditem.itemid)) {
                    selecteditemprice += Number(selecteditem.price)
                }
            }
        })
    
        // IF MATCHING ITEM IS FOUND AND THE SELECTED ITEM IS A BOOTH UPGRADE
        if (matchingRegisteredItem && boothupgradeRegitems.includes(selectedItem.itemid)) {
            // DEDUCT SELECTED ITEM'S PRICE FERROM TOTAL AMOUNT
            totalamount = Number(totalamount) - Number(selecteditemprice);
            global.$upgradepricediff = Number(selecteditemprice - matchingRegisteredItem.price)
            // CALCULATE PRICE DIFFREENCE ( PENDING AMOUNTS ALSO)
            let price = Number(selecteditemprice) - Number(matchingRegisteredItem.price - global.pendingamounts); 
            // REMOVE NEGATIVE PRICE ISSUE
            if (price < 0) price = 0
            // ADD NEW TOTAL AMOUNT
            totalamount = price
    
        }
        return totalamount 
    }

    return totalamount
}

function checkBoothRegitemSelection(regSetup) {
    if (regSetup?.registrationconditions?.boothregitems) {
        let updateRegitems = regSetup.registrationconditions.boothregitems
        let boothSelect = global.$regitems.filter((regitem) => regitem.isChecked === true && regitem.dropdownvalue.toLowerCase() !== "select")
        let newSelect = boothSelect.filter((item) => updateRegitems.includes(item.itemid)) 
        if (newSelect.length > 1) {
            return false;
        }
    }
    return true;
}

function checkRequiredRegitems(requiredregitemscondition) {

    let purchaseItems = global.$regitems.filter((item) => item.isChecked)
    let result = true;
    let conditionValue = '';

    for (const condition of requiredregitemscondition) {
        const matchingItems = purchaseItems.filter(item =>
            item.selecteddropdown && item.selecteddropdown.some(subitem => subitem.itemid === condition.selecteditem) ||
            item.itemid === condition.selecteditem
        );
        
        if (matchingItems[0]?.itemid === condition.selecteditem) {
    
            conditionValue = condition;
            if (condition.value === "subitem") {
                result = purchaseItems.some(item =>                           
                    item.selecteddropdown && item.selecteddropdown.length > 0 && (
                        (condition.condition === "&&" && checkAllSubiteminclue(item, condition)) || 
                        (condition.condition === "||" && checkSubiteminclude(item, condition)) 
                    )
                );
            }else if (condition.value === "regitem") {
                result = purchaseItems.some(item =>
                    (condition.condition === "&&" && checkIfIncludes(purchaseItems, condition.purchaseitems)) ||
                    (condition.condition === "||" && checkItemIncludesCondition(purchaseItems, condition.purchaseitems))
                );
            } else if (condition.value === "both") {
                result = purchaseItems.some(item =>
                    item.selecteddropdown && item.selecteddropdown.length > 0 && (
                        (condition.condition === "&&" && checkAllSubiteminclue(item, condition)) ||
                        (condition.condition === "||" && checkSubiteminclude(item, condition))
                    )
                ) || purchaseItems.some(item =>
                    (condition.condition === "&&" && checkIfIncludes(purchaseItems, condition.purchaseitems)) ||
                    (condition.condition === "||" && checkItemIncludesCondition(purchaseItems, condition.purchaseitems))
                );
            }
        }
    }
    return {result,conditionValue};
    
}
function checkAllSubiteminclue(item, condition) {
    if (item.selecteddropdown) {
        return condition.purchaseitems.every(idToCheck => item.selecteddropdown.some(obj => obj.itemid === idToCheck));
    }
    return false;
}
function checkSubiteminclude(item, condition) {
    if (item.selecteddropdown) {
        return item.selecteddropdown.some(obj => condition.purchaseitems.includes(obj.itemid));
    }
    return false;
}
function checkIfIncludes(arr, ids) {
    return ids.every(idToCheck => arr.some(obj => obj.data5 === idToCheck));
}
function checkItemIncludesCondition(regitems,condition) {
    return regitems.some(obj => condition.includes(obj.data5))
}

function checkItemQuantityLimit(selectedItem, itemLimits) {
    let isValid = true;         // Flag to track if the quantity is within the limit.
    let errorMessage = "";      // Message to store the error, if any.
    let quantity = 0;          // Variable to hold the updated quantity.

    if (isUserLoggedin()) {
        const regItemsJSON = JSON.parse(global.$regitemsjson);
        regItemsJSON.forEach(regitem => {
            if (regitem.itemid === selectedItem.itemid) {
                // Convert the quantity to a number and add the additional quantity from the regitem.
                quantity += Number(selectedItem.quantity) + Number(regitem.qty);
            }
        });
    }
    else {
        // If the user is not logged in, simply consider the selected item's quantity.
        quantity = Number(selectedItem.quantity);
    }

    // Iterate through the itemLimits to check if the quantity exceeds the limit.
     itemLimits.forEach(itemLimit => {
        if (itemLimit.itemid === selectedItem.itemid && quantity > itemLimit.limit) {
            isValid = false;
            errorMessage = itemLimit.errormessage;
        }
    });

    return { isValid, errorMessage }; // Return the result and error message.
}

export default {
    checkItemQuantityLimit,
    checkRequiredRegitems,
    getBoothUpgradeRegitemPrice,
    checkBoothRegitemSelection,
    sortRegItems,
    enableRegComponents,
    enableUpdateRegComponents,
    isUserSelectedGuest,
    isGuestEnabledFromConfig,
    getDefaultTab,
    getRegItemsForSignupCount,
    isRegDiscountExist,
    getUserSelectedRegItems,
    getLoggedInUserRegItems,
    getLoggedInUserPendingAmount,
    isRadioInput,
    isCheckboxInput,
    isNumberInput,
    isDropdownInput,
    getRegItemStyle,
    getRegItemsForGroup,
    isUserAlreadyRegisteredForThisItem,
    getValueFromMeCard,
    isAttendeeTab,
    isExhibitorTab,
    isSponsorTab,
    clearGlobalVariables,
    isUserLoggedin,
    isExhibitorTabEnabled,
    isSponsorTabEnabled,
    isAttendeeTabEnabled,
    isUserType,
    isGuestAdded,
    activeTabIs,
    covertGroupIDToSectionID,
    printGlobalRegItems,
    printGlobalAmounts,
    isGuestUserDetails,
    isGuestUserRegItem,
    isGuestUpdate,
    isCommonEmail,
    disableGuestRequiredFields,
    guestCheck,
    checkGuestRegItem,
    validateMetro,
    requiredGuestSection,
    selectedRegOptions,
    createdatetime,
    isTab1Enabled,
    isTab2Enabled,
    loginUserType,
    isTab3Enabled,
    isTab4Enabled,
    isTab5Enabled,
    convertGroupname,
    validateSelectedRegItemPrice
  };
  