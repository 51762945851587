import React from 'react';
import RegOptionsSection from './RegOptionsSection';
import CommonFunctions from '../RegCommon/CommonFunctions';
import RegOptionError from './RegOptionError';

class RegOptionsContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataloaded: false,
            hideRegitems:[],
            errorPopup: false,
            errorMessage:''
        }

        this.refreshComponent = this.refreshComponent.bind(this);
        this.conditionalRegitems = this.conditionalRegitems.bind(this);
        this.updateStateWithConditions = this.updateStateWithConditions.bind(this);
        this.getUpdatedHideRegitems = this.getUpdatedHideRegitems.bind(this);
        this.requiredregitemscondition = this.requiredregitemscondition.bind(this);
        this.showErrorPopup = this.showErrorPopup.bind(this)
    }

    refreshComponent() {
        this.props.onPaymentAmountChanged()
    }

    showErrorPopup = (condition) => {
        this.setState(prevState => ({
            errorPopup: !prevState.errorPopup,
            errorMessage: condition ? condition.errorMessage : ""
        }));
    }

    
    conditionalRegitems(selected) {
        let regItems = global.$regitems.filter((regitem) => regitem.isChecked === true );
        const tabId = this.props.tabid;
        const regSetup = global.$registrationform.setup[tabId];

        if (CommonFunctions.isUserLoggedin()) {
            let purchaseItems = global.$regitems.filter(regitem => {
                return JSON.parse(global.$regitemsjson).some(item => item.itemid === regitem.data5);
            });
            regItems.push(...purchaseItems);

            // SELECTED REGITEM DOWNGRADE AMOUNT CHECK
            let result = CommonFunctions.validateSelectedRegItemPrice(regSetup,selected,JSON.parse(global.$regitemsjson));
            if (result) {
                this.showErrorPopup({errorMessage:"Downgrade payments are not allowed"} );
                return false;
            }
            
            
        }
        
        // CALL THE CONDITIONS FUNCTIONS
        this.updateStateWithConditions(regItems);

        // CHECK BOOTH REGITEM SELECT (MULTIPLE ITEM NOT ALLOWEDED)
        let boothSelect = CommonFunctions.checkBoothRegitemSelection(regSetup)
        if (!boothSelect) {
            this.showErrorPopup({errorMessage:"Select any one item"});
            return false;
        }
        
        // CHECK REQUIRED REGITEMS CONDTIONS
        let result = this.requiredregitemscondition(regItems,selected)
        if (!result.result) {
            this.showErrorPopup(result.conditionValue);
            return false
        }

        let limtresult = this.checkRegitemLimt(selected)
        if (!limtresult.isValid) {
            this.showErrorPopup(limtresult);
            return false
        }

        return true
    }

    

    render() {
        global.$regitems.map(regitem => regitem.hidden = this.state.hideRegitems.includes(regitem.data5));

        return (

            <>
                <div className="reg-options-div" style={global.$registrationform.styles.regoptions.maindiv}>
                    {/* section names from webconfig (one,two,three,four,five) */}
                    <RegOptionsSection formtype={this.props.type} tabid={this.props.tabid} groupid="one" refreshComponent={this.refreshComponent} conditionalRegitems={this.conditionalRegitems}/>
                    <RegOptionsSection formtype={this.props.type} tabid={this.props.tabid} groupid="two"  refreshComponent={this.refreshComponent} conditionalRegitems={this.conditionalRegitems}/>
                    <RegOptionsSection formtype={this.props.type} tabid={this.props.tabid} groupid="three" refreshComponent={this.refreshComponent} conditionalRegitems={this.conditionalRegitems}/>
                    <RegOptionsSection formtype={this.props.type} tabid={this.props.tabid} groupid="four" refreshComponent={this.refreshComponent} conditionalRegitems={this.conditionalRegitems}/>
                    <RegOptionsSection formtype={this.props.type} tabid={this.props.tabid} groupid="five" refreshComponent={this.refreshComponent} conditionalRegitems={this.conditionalRegitems}/>
                </div>
                <RegOptionError
                    showPopup={this.state.errorPopup}
                    errorMessage={this.state.errorMessage}
                    closePopup={this.showErrorPopup}
                />
            </>
        );
    }

    checkRegitemLimt(selected) {
        const tabId = this.props.tabid;
        const regSetup = global.$registrationform.setup[tabId];
        let isValid = true;         
        let errorMessage = "";   

        if (regSetup?.registrationconditions?.regitemlimits) {
            let regitemlimits = regSetup.registrationconditions.regitemlimits;
            return CommonFunctions.checkItemQuantityLimit(selected,regitemlimits)
        }
        return {isValid,errorMessage}
    }

    // CHECK THE REQUIRED REGITEM PURCHASE OR NOT
    requiredregitemscondition(regItems,selected) {

        const tabId = this.props.tabid;
        const regSetup = global.$registrationform.setup[tabId];
        let result = true;
        let conditionValue = '';

        if (regSetup?.registrationconditions?.requiredregitemscondition) {

            let requiredregitemscondition = regSetup.registrationconditions.requiredregitemscondition;
            let regitems = regItems.filter(item => item.dropdownvalue.toLowerCase() !== "select");

            for (const condition of requiredregitemscondition) {
                if (selected.data5 === condition.selecteditem) {
                    /*  
                        ? if (condition.value === "subitem") CHECK THE DROPDOWN SUBITEM
                        ? if (condition.value === "regitem") CHECK THE REGITEM
                        ? if (condition.value === "both") CHECK REGITEMS AND SUBITEMS
                        * "&&" - CHECK ALL VALUES INCLUDES 
                        * "||" - CHECK ANY ONE OF THE VALUES INCLUDES
                    */
                    conditionValue = condition;
                    if (condition.value === "subitem") {
                        result = regitems.some(item =>                           
                            item.selecteddropdown && item.selecteddropdown.length > 0 && (
                                (condition.condition === "&&" && this.checkAllSubiteminclue(item, condition)) || 
                                (condition.condition === "||" && this.checkSubiteminclude(item, condition)) 
                            )
                        );
                    }else if (condition.value === "regitem") {
                        result = regitems.some(item =>
                            (condition.condition === "&&" && this.checkIfIncludes(regitems, condition.purchaseitems)) ||
                            (condition.condition === "||" && this.checkItemIncludesCondition(regitems, condition.purchaseitems))
                        );
                    } else if (condition.value === "both") {
                        result = regitems.some(item =>
                            item.selecteddropdown && item.selecteddropdown.length > 0 && (
                                (condition.condition === "&&" && this.checkAllSubiteminclue(item, condition)) ||
                                (condition.condition === "||" && this.checkSubiteminclude(item, condition))
                            )
                        ) || regitems.some(item =>
                            (condition.condition === "&&" && this.checkIfIncludes(regitems, condition.purchaseitems)) ||
                            (condition.condition === "||" && this.checkItemIncludesCondition(regitems, condition.purchaseitems))
                        );
                    }
                }
            }
            
        }
        return {result,conditionValue};
    }

    updateStateWithConditions = (regItems) => {
        let selectedRegitemsArray = [];

        const tabId = this.props.tabid;
        const regSetup = global.$registrationform.setup[tabId];

        if (regSetup?.registrationconditions?.showhideregitemsconditions) {
            let showhideregitemsconditions = regSetup.registrationconditions.showhideregitemsconditions;
            showhideregitemsconditions.forEach(condition => {
                regItems.forEach(regitem => {
                  if (regitem.inputtype === "checkbox" && condition.selectedregitems.includes(regitem.data5)) {
                      if (regitem.isChecked === true) {
                        
                          // CHECK SELECTEDREGITEMS INCLUDES ANY ONE selectedRegitems
                          const result2 = regItems.some(obj => condition.selectedregitems.includes(obj.data5));
                          if (result2) {
                              // HIDE THE REGITEMS
                              selectedRegitemsArray = condition.hideregitems.filter(item => !selectedRegitemsArray.includes(item));
                              // SHOW THE REGITEMS
                              selectedRegitemsArray.push(...condition.selectedregitems);
                          }
      
                          const result = this.checkIfIncludes(regItems, condition.selectedregitems);
                          if (result) {
                              // HIDE THE REGITEMS
                              selectedRegitemsArray = condition.selectedregitems.filter(item => !selectedRegitemsArray.includes(item));
                              // SHOW THE REGITEMS
                              selectedRegitemsArray.push(...condition.hideregitems);
                          }
                      }
                      else {
                        // SELECTED ITEMS NOT INCLUDES selectedRegitems IDS - THEN HIDE ALL OPTIONS
                        selectedRegitemsArray = condition.hideregitems.filter(item => condition.selectedregitems.includes(item));
                      }
                  }
      
                  if (regitem.inputtype === "dropdown" && condition.value === "subitem" && regitem.selecteddropdown) {
      
                      if (regitem.dropdownvalue === "select") {
                          selectedRegitemsArray.push(...condition.hideregitems, ...condition.selectedregitems);
                          this.removePreviousRegItems(condition)
                      }
                      else {
                          if (this.checkIfIncludes(regItems, condition.selectedregitems)) {
                              this.removePreviousRegItems(condition)
                              this.state.hideRegitems.push(...condition.hideregitems);
                              return selectedRegitemsArray.push(...condition.selectedregitems);
                          }
                          else if (regitem.selecteddropdown.some(obj => condition.selectedregitems.includes(obj.itemid))) {
                              this.removePreviousRegItems(condition)
                              this.state.hideRegitems.push(...condition.hideregitems);
                              return selectedRegitemsArray.push(...condition.selectedregitems);
                          }
                      }
      
                  }
                  else if (regitem.inputtype === "dropdown" && condition.value === "regitem") {
      
                      let regitems = regItems.filter(item => item.dropdownvalue !== "select");
                      if (this.checkIfIncludes(regitems, condition.selectedregitems)) {
      
                          this.removePreviousRegItems(condition)
                          this.state.hideRegitems.push(...condition.hideregitems);
                          return selectedRegitemsArray.push(...condition.showingregitems);
                      }
                      else if (this.checkIfInclude(regitems, condition)) {
      
                          this.removePreviousRegItems(condition)
                          this.state.hideRegitems.push(...condition.showingregitems);
                          return selectedRegitemsArray.push(...condition.hideregitems);
                      }
                      else {
                          return selectedRegitemsArray.push(...condition.hideregitems, ...condition.showingregitems);
                      }
                  }
                  
                  });
              });
      
              this.setState(prevState => ({
                  hideRegitems: this.getUpdatedHideRegitems(prevState.hideRegitems, selectedRegitemsArray, []),
              }));
        }
        
        

    };

    checkAllSubiteminclue(item, condition) {
        if (item.selecteddropdown) {
            return condition.purchaseitems.every(idToCheck => item.selecteddropdown.some(obj => obj.itemid === idToCheck));
        }
        return false;
    }
    checkSubiteminclude(item, condition) {
        if (item.selecteddropdown) {
            return item.selecteddropdown.some(obj => condition.purchaseitems.includes(obj.itemid));
        }
        return false;
    }

    // CHECK ANY REGITEM ID INCLUDES OR NOT
    checkItemIncludesCondition(regitems,condition) {
        return regitems.some(obj => condition.includes(obj.data5))
    }

    removePreviousRegItems(condition) {
        global.$regitems.forEach((item) => {
            if (condition.hideregitems.includes(item.data5)) item.isChecked = false;
        });
    }

    checkIfInclude(regItems,condition) {
        return regItems.some((obj) => condition.selectedregitems.includes(obj.data5));
    }

    checkIfIncludes(arr, ids) {
        return ids.every(idToCheck => arr.some(obj => obj.data5 === idToCheck));
    }

    getUpdatedHideRegitems(currentHideRegitems, showingItems, hidingItems) {
        const newHideRegitems = currentHideRegitems.filter(item => !showingItems.includes(item));
        return [...newHideRegitems, ...hidingItems];
    }

}

export default RegOptionsContainer;